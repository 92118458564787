import React from "react";
import Menu from "./menu";
import Slider from "./slider";
import About from "./about";
import Services from "./services";
import Gallery from "./gallery";
import Contact from "./contact";
import Footer from "./footer";
import { photos } from "../components/gallery/photos";
import "./app.css";

const App = () => {
  return (
    <div>
      <Menu />
      <Slider />
      <div className="container">
        <About />
        <Services />
        <Gallery photos={photos.slice(0, 31)} />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default App;
