import React from "react";

import "./styles.css";

const Contact = () => {
  return (
    <div>
      <section id="contact" className="page">
        <div className="align-center">
          <header className="section-header">
            <h2>Contact</h2>
          </header>
        </div>
        <div className="section-contact">
          <div className="row">
            <div className="contact-column">
              <div className="contact-logo">
                <img src="img/select-logo-gray.png" alt="" />
              </div>
              <div className="contact-info">
                <p className="paragraph">
                  <i className="fas fa-map-marker-alt"></i>
                  <h4>
                    <a
                      href="https://goo.gl/maps/X67eFrAsTGFpFc369"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2102 N. Pearl St., Ste. 402, Tacoma, WA 98406
                    </a>
                  </h4>
                </p>
                <p>
                  <i className="fas fa-phone"></i>
                  <h4>Phone: (425) 242 - 6641</h4>
                </p>
                <p>
                  <i className="fas fa-fax"></i>
                  <h4>Fax: (425) 669 - 9426</h4>
                </p>
                <p>
                  <i className="fas fa-envelope"></i>
                  <h4>
                    <a
                      href="mailto:dave@selectcorp.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Email: dave@selectcorp.org
                    </a>
                  </h4>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
