import React from "react";

import "./styles.css";
const About = () => {
  return (
    <div>
      <section id="about" className="page">
        <div className="align-center">
          <header className="section-header">
            <h2>ANYWHERE, ANYTIME, ON ROAD</h2>
          </header>
        </div>
        <div className="section-about">
          <div className="row">
            <div className="column">
              <h3 className="heading-tertiary">
                Select Corporation is a family owned interstate trucking
                company.
              </h3>
              <p>
                I started as a truck driver, then became an owner operator, and
                now for more then 3 years we keep growing our fleet bit by bit.
              </p>
              <p>
                By going through all steps, I can proudly say that we know all
                Ins and Outs of this business. Being part of one of the biggest
                industries in the U.S. with the market being worth $791.7
                Billion in 2019, we understand how important is to get your
                freight safely, reliable and on time.
              </p>
              <p>
                We decided not to limit our services, and as for now, the
                company is divided in 3 main divisions, 1st - Open Deck
                division, responsible for Step deck/Flatbed/Conestoga loads, 2nd
                Reefer division, and 3rd Dryvan division.
              </p>
              <p>
                Safety is our priority, we appreciate and value each Driver and
                Owner Operator, our team is here to help and assist you anytime.
                Trucking is not an easy job, we make sure by doing this job you
                not only keep America moving, but you also feel comfortable,
                enjoy, and of course get remunerated by making good money, in
                order to do so we keep improving our services by implementing
                the newest technology and IT systems.
              </p>
              <p>
                Our professional dispatchers will keep you busy with local
                loads, dedicated, or day by day best rates. We are ELD
                compliant.Either you are a driver or owner operator, looking for
                a safe company to work with, or a broker looking for a partner,
                contact our team and we will be more than happy to assist you.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
