import React from "react";

import "./styles.css";

const Footer = () => (
  <div>
    <footer className="footer bg-dark">
      <div className="social">
        <a href="https://www.facebook.com/Select-Corporation-102354318009752">
          <i className="fab fa-facebook fa-2x"></i>
        </a>
        <a href="#home">
          <i className="fab fa-twitter fa-2x"></i>
        </a>
        <a href="#home">
          <i className="fab fa-youtube fa-2x"></i>
        </a>
        <a href="#home">
          <i className="fab fa-linkedin fa-2x"></i>
        </a>
      </div>
      <p>Copyright &copy; {new Date().getFullYear()} Select Corp.</p>
    </footer>
  </div>
);

export default Footer;
