export const photos = [
  {
    src: "img/gallery/1.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "img/gallery/2.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "img/gallery/3.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "img/gallery/4.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "img/gallery/5.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "img/gallery/6.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "img/gallery/7.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "img/gallery/8.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "img/gallery/9.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "img/gallery/10.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "img/gallery/11.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "img/gallery/12.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "img/gallery/13.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "img/gallery/14.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "img/gallery/15.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "img/gallery/16.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "img/gallery/17.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "img/gallery/18.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "img/gallery/19.jpg",
    width: 3,
    height: 2,
  },
  {
    src: "img/gallery/20.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "img/gallery/21.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "img/gallery/22.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "img/gallery/23.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "img/gallery/24.jpg",
    width: 2,
    height: 3,
  },
  {
    src: "img/gallery/25.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "img/gallery/26.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "img/gallery/27.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "img/gallery/28.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "img/gallery/29.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "img/gallery/30.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "img/gallery/31.jpg",
    width: 4,
    height: 3,
  },
];
