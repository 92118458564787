import React, { useEffect } from "react";
import "./style.css";

const Menu = () => {
  useEffect(() => {
    const navbar = document.getElementById("navbar");
    let scrolled = false;

    window.onscroll = function () {
      if (window.pageYOffset > 200) {
        navbar.classList.remove("top");
        if (!scrolled) {
          navbar.style.transform = "translateY(-70px)";
        }
        setTimeout(() => {
          navbar.style.transform = "translateY(0)";
          scrolled = true;
        }, 200);
      } else {
        navbar.classList.add("top");
        scrolled = false;
      }
    };
  });
  return (
    <header className="hero" id="home">
      <div className="navbar top" id="navbar">
        <h1 className="logo">
          <img src="img/logo-green-white.png" alt="" />
        </h1>
        <nav>
          <ul>
            <li>
              <a href="#home">HOME</a>
            </li>
            <li>
              <a href="#about">ABOUT</a>
            </li>
            <li>
              <a href="#services">SERVICES</a>
            </li>
            <li>
              <a href="#gallery">GALLERY</a>
            </li>
            <li>
              <a href="#contact">CONTACT</a>
            </li>
            <li>
              <a href="https://intelliapp.driverapponline.com/c/1674404">
                APPLY NOW
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Menu;
