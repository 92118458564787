import React from "react";
import "./styles.css";

const Services = () => {
  return (
    <div>
      <section id="services" className="page services">
        <div className="align-center">
          <header className="section-header">
            <h2>Services</h2>
          </header>
        </div>

        <div className="row">
          <div className="cards">
            <div className="cards-sections">
              <h3>Reefer</h3>
              <p>
                Temperature-controlled freight requires more attention, our
                professional drivers will set and make sure that proper
                temperature is maintained during transportation.
              </p>
            </div>
            <div className="img-1"></div>
          </div>
          <div className="cards">
            <div className="cards-sections">
              <h3>DryVan</h3>
              <p>
                We use only up to 5 years old Dry Van Trailers, equipped with E
                Tracks, that keeps your freight safe.
              </p>
            </div>
            <div className="img-2"></div>
          </div>
          <div className="cards">
            <div className="cards-sections">
              <h3>Flatbed / Conestoga</h3>
              <p>
                If your freight needs to be loaded or unloaded sideways, our
                Flatbed division is here to help, we’ll insure it’s secured
                safe, and in case you need it, tarped.
              </p>
            </div>
            <div className="img-3"></div>
          </div>
          <div className="cards">
            <div className="cards-sections">
              <h3 className="heading-tertiary">Step Deck</h3>
              <p>Need to move a taller freight? </p>
              <p>Or over dimensional? </p>
              <p>
                No problem, we can handle it. All our Step Deck Trailers are
                equipped with 16’ ramps, and drivers are trained to load and
                unload all types of equipment.
              </p>
            </div>
            <div className="img-4"></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
